import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Button, message, Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';
import config from '../config';
import CardContent from '../components/CardContent';
import MessageInput from '../components/MessageInput';
import '../styles/App.scss';

const { Content } = Layout;

const InteractiveAgent = ({ profile }) => {
    const { t } = useTranslation();
    const [cards, setCards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(-1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loadingCards, setLoadingCards] = useState(new Set());
    const [expandedImages, setExpandedImages] = useState({});
    const cardsRef = useRef(cards);
    const [pendingFileKeys, setPendingFileKeys] = useState([]);

    useEffect(() => {
        cardsRef.current = cards;
    }, [cards]);

    const handleWebSocketMessage = useCallback((event) => {
        try {
            const parsedMessage = JSON.parse(event.data);
            console.log("Received WebSocket message:", parsedMessage);

            if (parsedMessage.type === 'file_upload_complete' && parsedMessage.file_key) {
                setPendingFileKeys(prev => [...prev, parsedMessage.file_key]);
                return;
            }

            setCards(prevCards => {
                const updatedCards = [...prevCards];
                const lastCardIndex = updatedCards.length - 1;

                if (lastCardIndex < 0) {
                    return prevCards; // No cards to update
                }

                if (parsedMessage.message) {
                    if (typeof parsedMessage.message === 'object' && parsedMessage.message.is_image) {
                        updatedCards[lastCardIndex].content += `\n![Image](${parsedMessage.message.url})`;
                    } else if (typeof parsedMessage.message === 'string') {
                        updatedCards[lastCardIndex].content += parsedMessage.message;
                    }
                }

                console.log("Updated cards:", updatedCards);
                return updatedCards;
            });

            if (parsedMessage.is_final || parsedMessage.type === 'end') {
                console.log("Message is final, stopping processing");
                setIsProcessing(false);
                setLoadingCards(prevLoadingCards => {
                    const updatedLoadingCards = new Set(prevLoadingCards);
                    updatedLoadingCards.delete(cardsRef.current.length - 1);
                    return updatedLoadingCards;
                });
            }
        } catch (error) {
            console.error('Error processing WebSocket message:', error);
            message.error('Error processing server response');
        }
    }, []);

    const { sendMessage, readyState } = useWebSocket(`${config.agentUrl}?user_id=${profile.id}`, {
        onOpen: () => console.log('WebSocket connection opened.'),
        onClose: () => {
            console.log('WebSocket connection closed.');
            setIsProcessing(false);
        },
        onError: (error) => {
            console.error('WebSocket error:', error);
            message.error('WebSocket connection error.');
            setIsProcessing(false);
        },
        shouldReconnect: () => true,
        onMessage: handleWebSocketMessage,
    });

    useEffect(() => {
        fetchResponses();
    }, [profile.id]);

    const fetchResponses = async () => {
        try {
            const response = await fetch(`${config.promptsUrl}?user_id=${profile.id}`);
            if (response.ok) {
                const data = await response.json();
                const formattedCards = data.results.map(formatCard);
                setCards(formattedCards);
                setCurrentCardIndex(-1);
            } else {
                message.error('Failed to load responses.');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
            message.error('Error fetching responses.');
        }
    };

    const formatCard = (result) => ({
        title: result.prompt,
        content: result.response,
        files: result.files || [],
        feedback: result.feedback,
        prompt_id: result.prompt_id
    });

    const createBlankCard = useCallback(() => {
        setCards(prevCards => {
            const newCards = [...prevCards, { title: 'New Message', content: '', type: 'user_message' }];
            setCurrentCardIndex(newCards.length - 1);
            return newCards;
        });
    }, []);

    const handleSendClick = useCallback(({ message }) => {
        if ((message.trim() || pendingFileKeys.length > 0) && !isProcessing) {
            setIsProcessing(true);
            const messageToSend = {
                type: 'text',
                prompt: message,
                file_keys: pendingFileKeys,
                user_id: profile.id
            };
            sendMessage(JSON.stringify(messageToSend));

            setCards(prevCards => {
                const updatedCards = [...prevCards];
                const newCard = {
                    title: message || '',
                    content: '',
                    type: 'user_message',
                    fileKeys: pendingFileKeys
                };
                updatedCards.push(newCard);
                setCurrentCardIndex(updatedCards.length - 1);
                setLoadingCards(new Set([updatedCards.length - 1]));
                return updatedCards;
            });

            setPendingFileKeys([]);
        } else if (isProcessing) {
            message.error("Please wait for the current response.");
        } else {
            message.error("Message cannot be empty!");
        }
    }, [isProcessing, profile.id, sendMessage, pendingFileKeys]);

    const handleFileUploadStart = useCallback(() => {
        createBlankCard();
    }, [createBlankCard]);

    const handleFileUploadComplete = useCallback((fileKey) => {
        setPendingFileKeys(prev => [...prev, fileKey]);
    }, []);

    const deleteFile = async (fileKey) => {
        try {
            await fetch(config.deleteFilesUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        file_key: fileKey,
                        user_id: profile.id,
                        temp_file: "true"
                    }),
            });
            message.success('File deleted successfully.');
            setPendingFileKeys(prev => prev.filter(key => key !== fileKey));
        } catch (error) {
            console.error('Error deleting file:', error);
            message.error('Error deleting file.');
        }
    };

    const handlePrevClick = useCallback(() => {
        setCurrentCardIndex(prevIndex => {
            if (prevIndex <= 0) return cards.length - 1;
            return prevIndex - 1;
        });
    }, [cards.length]);

    const handleNextClick = useCallback(() => {
        setCurrentCardIndex(prevIndex => {
            if (prevIndex >= cards.length - 1) return 0;
            return prevIndex + 1;
        });
    }, [cards.length]);

    const handleVote = async (promptId, like) => {
        try {
            await fetch(config.voteUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    prompt_id: promptId,
                    user_id: profile.id,
                    like: like ? 2 : 1,
                }),
            });

            setCards((prevCards) =>
                prevCards.map((card) =>
                    card.prompt_id === promptId ? { ...card, feedback: like ? 2 : 1 } : card
                )
            );
        } catch (error) {
            console.error('Error voting:', error);
            message.error('Error submitting vote.');
        }
    };

    const handleDelete = async (promptId) => {
        try {
            await fetch(config.deletePromptsUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt_id: promptId }),
            });

            setCards((prevCards) => {
                const updatedCards = prevCards.filter((card) => card.prompt_id !== promptId);
                setCurrentCardIndex(prevIndex => {
                    const newIndex = Math.min(prevIndex, updatedCards.length - 1);
                    return newIndex >= 0 ? newIndex : -1;
                });
                return updatedCards;
            });

            message.success('Prompt deleted successfully.');
        } catch (error) {
            console.error('Error deleting prompt:', error);
            message.error('Error deleting prompt.');
        }
    };

    const toggleImageExpansion = (imageSrc) => {
        setExpandedImages(prev => ({...prev, [imageSrc]: !prev[imageSrc]}));
    };

    const renderCard = useCallback(() => {
        if (currentCardIndex === -1) {
            return (
                <div className="default-card-content">
                    <img src="/logo_omniskills_bw.png" alt="Logo" className="logo" />
                    <p>{t('HELLO')} <strong>{profile?.given_name || profile?.name || ""}</strong>.</p>
                    <p>{t('AMMA_DESCRIPTION')}</p>
                </div>
            );
        }
        const card = cards[currentCardIndex];
        if (!card) {
            console.error(`No card found at index ${currentCardIndex}`);
            return <div>Error: No card found</div>;
        }
        return (
            <CardContent
                card={card}
                handleVote={handleVote}
                handleDelete={handleDelete}
                expandedImages={expandedImages}
                toggleImageExpansion={toggleImageExpansion}
                loadingCards={loadingCards}
                currentCardIndex={currentCardIndex}
            />
        );
    }, [cards, currentCardIndex, expandedImages, handleDelete, handleVote, loadingCards, profile?.given_name, profile?.name, t]);

    return (
        <Layout className="interactive-agent-layout">
            <Content className="interactive-agent-content">
                <div className="card-container">
                    <div className="navigation-buttons">
                        <Button type="primary" icon={<LeftOutlined />} onClick={handlePrevClick} className="navigation-button" />
                        <Button type="primary" icon={<RightOutlined />} onClick={handleNextClick} className="navigation-button" />
                    </div>
                    <Card title={t('AMMA_TITLE')} className="interactive-agent-card">
                        {renderCard()}
                    </Card>
                </div>
                <div className="message-input-container">
                    <MessageInput
                        isProcessing={isProcessing}
                        handleSendClick={handleSendClick}
                        readyState={readyState}
                        sendMessage={sendMessage}
                        userId={profile.id}
                        onFileUploadStart={handleFileUploadStart}
                        onFileUploadComplete={handleFileUploadComplete}
                        deleteFile={deleteFile}
                        pendingFileKeys={pendingFileKeys}
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default InteractiveAgent;
